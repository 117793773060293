import {formatMonth} from "@/lib/dateTimeUtilities"

export default class Costs {
  quoteDollars = 0
  invoicedDollars = 0
  invoicedPercent = 0
  currentDollars = 0
  currentToDateDollars = 0
  currentPercent = 0
  toInvoicePercent = 0
  toInvoiceDollars = 0
  newInvoicedTotal = 0
  summary = []

  costDescriptions = []

  job = undefined

  constructor(vue, invoicePeriod, startDate, endDate) {
    this.vue = vue
    this.invoicePeriod = invoicePeriod
    this.startDate = startDate
    this.endDate = endDate
  }

  recalculateCosts(what) {
    const value = Math.round(100 * this.invoicedDollars / this.quoteDollars)
    this.invoicedPercent = Number.isFinite(value) ? value : "--"

    this.currentToDateDollars = this.currentDollars + this.invoicedDollars
    const value2 = Math.round(100 * this.currentToDateDollars / this.quoteDollars)
    this.currentPercent = Number.isFinite(value) ? value2 : "--"

    if (what === "percent") {
      const amount = Math.round((100 * (this.toInvoiceDollars + this.invoicedDollars)) / this.quoteDollars)
      this.toInvoicePercent = Number.isFinite(amount) ? amount : 100
    } else if (what === "amount") {
      this.toInvoiceDollars = ((this.toInvoicePercent / 100) * this.quoteDollars) - this.invoicedDollars
      this.toInvoiceDollars = Math.max(0, Math.round(100 * this.toInvoiceDollars) / 100)
    }
    this.newInvoicedTotal = this.toInvoiceDollars + this.invoicedDollars
  }

  processWfmJob(job) {
    this.job = job
  }

  processWfmCosts(response) {
    let thisMonthAccruedCosts = 0

    const _this = this
    const endOfMonth = (new Date(this.endDate + "T23:59")).getTime()
    const startOfMonth = (new Date(this.startDate + "T00:00")).getTime()

    if (response.Costs) {
      const costs = this.vue.$ensureArray(response.Costs.Cost)
      costs.forEach(function (cost) {
        const costTime = (new Date(cost.Date)).getTime()
        if (costTime > endOfMonth) return // skip as the cost is in the future

        const date = formatMonth(cost.Date)
        const dollars = parseFloat(cost.UnitPrice) * parseFloat(cost.Quantity)

        if (costTime >= startOfMonth) {
          thisMonthAccruedCosts += dollars
          let note = cost.Note ? cost.Note : ""
          if (_this.job.DateCreatedUtc.substr(0, 11) === cost.Date.substr(0, 11)) { // note which costs are probably estimates
            note += " DELETE ME"
          }
          note = _this.cleanText(note)
          _this.summary.push(`${date} ${note} (${_this.vue.$formatCurrency(dollars)})`)
          _this.costDescriptions.push(note)

        }
      })
    }

    this.currentDollars = thisMonthAccruedCosts
    this.toInvoiceDollars = thisMonthAccruedCosts
  }

  processWfmQuote(response) {
    const quote = response.Quote

    let quoteAmount = 0
    if (quote && quote.Costs) {
      const quoteCosts = this.vue.$ensureArray(quote.Costs.Cost)
      quoteCosts.forEach(function (quoteCost) {
        quoteAmount += parseFloat(quoteCost.Amount)
      })
    }
    this.quoteDollars = quoteAmount
  }

  processWfmInvoice(response) {
    const _this = this
    let invoices = []

    if (response.Invoices) {
      invoices = _this.vue.$ensureArray(response.Invoices.Invoice)
    }

    let invoicedCostsAmount = 0
    invoices.forEach(function (invoice) {
      // skip if not paid or approved
      if (!["Paid", "Approved"].includes(invoice.Status)) return
      
      const endOfMonth = (new Date(_this.endDate + "T00:00")).getTime()
      const invoiceDate = (new Date(invoice.Date)).getTime()
      if (invoiceDate >= endOfMonth) return // skip - future time

      if (invoice.Jobs.Job.Costs) {
        const invoicedCosts = _this.vue.$ensureArray(invoice.Jobs.Job.Costs.Cost)
        invoicedCosts.forEach(function (invoicedCost) {
          invoicedCostsAmount += parseFloat(invoicedCost.Amount)
        })
      }
    })
    _this.invoicedDollars = invoicedCostsAmount
  }

  processBwf(response) {
    this.comments = response.comments
    if (response.costs.percentage) {
      this.toInvoicePercent = response.costs.percentage
    }
    if (response.costs.amount) {
      this.toInvoiceDollars = Math.round(response.costs.amount, 2)
    }
  }

  cleanText(str) {
    if (str) {
      const A = str.replace(/([^.])$/, "$1.")
      return A.charAt(0).toUpperCase() + A.slice(1)
    } else {
      return ""
    }
  }

  copyActuals() {
    this.toInvoiceDollars = this.currentDollars
    this.recalculateCosts("percent")
  }

}