<script>
import {defineComponent} from "vue"
import {getStaffList} from "@/lib/lookup"
import {getWeekNumber, toLocalString, formatDate} from "@/lib/dateTimeUtilities"
import {leadProbabilities, leadStages} from "@/lib/constants"
import CreateProspect from "@/components/clients/CreateProspect.vue"
import {isNotEmpty, isNotNull} from "@/lib/validationRules"
import ContactEditor from "@/components/contacts/ContactEditor.vue"
import ClientPopup from "@/components/clients/ClientPopup.vue"

export default defineComponent({
  name: "LeadForm",
  permissions: ["Read Leads", "Modify Leads", "Delete Leads", "Modify Job Task", ...CreateProspect.permissions, ...ContactEditor.permissions, ...ClientPopup.permissions],
  components: {ContactEditor, CreateProspect, ClientPopup},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ["input"],
  computed: {
    clientName: function () {
      const option = this.clientOptions.find(client => client.value === this.lead.client_uuid)
      if (option) return option.text
      else return ""
    },
    quoteOptions: function () {
      const quoteOptions = []
      for (const quote of this.quotes) {
        if (quote.Client.UUID !== this.lead.client_uuid) continue
        // todo add filtering for already allocated quotes
        quoteOptions.push({text: `[${quote.ID}] ${quote.Name}`, value: quote.UUID})
      }
      return quoteOptions
    },
    clientOptions: function () {
      const clientOptions = []
      for (const client of this.clients) {
        clientOptions.push({text: client.Name, value: client.UUID})
      }
      return clientOptions
    },
    contactOptions: function () {
      if (!this.lead.client_uuid) return []
      const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
      if (!client) return []

      const contactOptions = []
      const contacts = this.$ensureArray(client.Contacts.Contact)
      for (const contact of contacts) {
        contactOptions.push({text: contact.Name, value: contact.UUID})
      }
      return contactOptions
    },
    selectedContact: function () {
      if (this.lead.contact_uuid) {
        const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
        if (!client) return []
        const contacts = this.$ensureArray(client.Contacts.Contact)
        return contacts.find(contact => contact.UUID === this.lead.contact_uuid)
      }
      return null
    },
    selectedClient: function () {
      if (this.lead.client_uuid) {
        return this.clients.find(client => client.UUID === this.lead.client_uuid)
      }
      return null
    }
  },
  methods: {
    isNotEmpty,
    isNotNull,
    isNotEmptyExceptLost(value) { // can be blank if stage is lost
      if (this.lead.stage === "lost") return true
      else return !!value
    },
    loadStaff() {
      getStaffList(this, (res) => {
        this.staff = []
        for (const staff of res) {
          this.staff.push({text: staff.name, value: staff.wfm_userid})
        }
      })
    },
    loadClients(clearCache = false) {
      return this.$WfmApi.get("client.api/list", {voidCache: clearCache}).then(response => {
        if (response.Clients) {
          this.clients = this.$ensureArray(response.Clients.Client)
        }
      })
    },
    loadQuotes() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      this.startDate = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      this.endDate = toLocalString(endDate).substr(0, 10).replace(/-/g, "")

      const query = {"from": this.startDate, "to": this.endDate, "detailed": "false", "voidCache": true}
      this.$WfmApi.get("quote.api/list", query).then(response => {
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
        }
      })
    },
    prospectCreated(prospect) {
      this.showCreateClientPopup = false
      this.loadClients(true).then(() => {
        this.$set(this.lead, "client_uuid", prospect.UUID)
        this.$set(this.lead, "contact_uuid", prospect.Contacts.Contact.UUID)
      })
    },
    saveContact() {
      const contact = this.$refs.contactEditor.getContact()

      const data = {
        Contact: {
          Client: {UUID: this.lead.client_uuid},
          Name: contact.name,
          Mobile: contact.mobile,
          Email: contact.email,
          Phone: contact.phone,
          Position: contact.position,
        }
      }

      this.$WfmApi.post("client.api/contact", data).then((response) => {
        this.loadClients(true)
        this.showCreateContactPopup = false
        this.$set(this.lead, "contact_uuid", response.Contact.UUID)
      })
    },
    massageSteps() {
      const steps = JSON.parse(JSON.stringify(this.lead.steps)) // copy
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].complete === 0) {
          steps[i].checked = false
          steps[i].notApplicable = false
        } else if (steps[i].complete === 1) {
          steps[i].checked = true
          steps[i].notApplicable = false
        } else {
          steps[i].checked = false
          steps[i].notApplicable = true
        }
      }
      this.steps = steps
    },
    toggle(idx, step) {
      step.notApplicable = !step.notApplicable
      this.$set(this.steps, idx, step)
    },
    advanceStage() {
      const stages = this.leadStages.map(stage => stage.value)
      const idx = stages.indexOf(this.lead.stage)
      if (idx < stages.length - 1) {
        this.lead.stage = stages[idx + 1]
      }
    },
    async checkFollowupTask() {
      // See if there is an existing follow-up task for the lead
      // If there is not, create one.
      // If there is, load it and see if it is done
      // If it is done then create a new task
      // If it is not done then update it
      if (this.lead.follow_up_task) {
        const task = await this.$BwfApi.get("staff-plan/task/" + this.lead.follow_up_task)
        if (task) {
          if (task.done) {
            return this.createFollowupTask()
          } else {
            // update and save task
            const follow_up_date = new Date(this.lead.follow_up_date)
            const {year, week} = getWeekNumber(follow_up_date)
            const period = year.toString() + "-" + week.toString().padStart(2, "0")
            task.year = year
            task.week = week
            task.period = period
            const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
            task.notes = `Lead Follow up: ${client.Name} - ${this.lead.title}\n ${this.lead.follow_up_action}`
            return this.createFollowupTask(task)
          }
        } else {
          return this.createFollowupTask()
        }
      } else {
        return this.createFollowupTask()
      }
    },
    createFollowupTask(existingTask) {
      const follow_up_date = new Date(this.lead.follow_up_date)
      const {year, week} = getWeekNumber(follow_up_date)
      const period = year.toString() + "-" + week.toString().padStart(2, "0")
      const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
      const task = existingTask || {
        job_id: -1,
        staff_plan_id: null,
        service_id: -1,
        staff_uuid: this.lead.owner_uuid,
        year: year,
        week: week,
        period: period,
        priority: 2,
        done: false,
        allocated_hours: 1,
        task_order: 0,
        notes: `Lead Follow up: ${client.Name} - ${this.lead.title}\n ${this.lead.follow_up_action}`,
      }
      return this.$BwfApi.post("staff-plan/task", task)
    },
    checkSalesTask() {
      const salesJob = (this.lead.is_prospect) ? this.settings.newSalesJob : this.settings.existingSalesJob

      this.$WfmApi.get(`job.api/get/${salesJob}`, {voidCache: true}).then((response) => {
        const tasks = this.$ensureArray(response.Job.Tasks.Task)
        for (const task of tasks) {
          if (task.Name === `Sales - ${this.clientName}`) {
            return // Don't create new task if one already exists
          }
        }
        this.createSalesTask(this.clientName)
      })
    },
    createSalesTask(clientName) {
      // Create a new Task under the sales job
      const salesJob = (this.lead.is_prospect) ? this.settings.newSalesJob : this.settings.existingSalesJob
      const data = {
        Task: {
          Job: salesJob,
          TaskUUID: this.settings.salesTaskUUID,
          Label: clientName,
          Billable: "false",
          EstimatedMinutes: 0
        }
      }
      this.$WfmApi.post("job.api/task", data)
    },
    save() {
      if (!this.$refs["my-form"].validate()) {
        this.$notify.show({type: "error", text: "There are incomplete fields"})
        return
      }

      const copy = {...this.lead}
      // close lead if it's won or lost, but only ever do it once
      if ((copy.stage === "won" || copy.stage === "lost") && !copy.closed) {
        copy.closed = formatDate(new Date(), "-")
      }

      copy.steps = []
      for (let i = 0; i < this.steps.length; i++) {
        copy.steps[i] = {name: this.steps[i].name}
        if (this.steps[i].notApplicable) {
          copy.steps[i].complete = -1
        } else if (this.steps[i].checked) {
          copy.steps[i].complete = 1
        } else {
          copy.steps[i].complete = 0
        }
      }

      this.checkFollowupTask().then((followUpTask) => {
        copy.follow_up_task = followUpTask.staff_plan_id
        this.$BwfApi.post("leads", copy).then(response => {
          this.$notify.toast("Saved")
          this.$emit("input", response.Lead)
          this.checkSalesTask()
          this.$notify.toast("Saved")
        })
      })
    },
    deleteLead() {
      this.$BwfApi.delete("leads/" + this.lead.lead_id).then(() => {
        this.$emit("input")
        this.$notify.toast("Deleted")
        this.showDeletePopup = false
      })
    }
  },
  data() {
    return {

      quotes: [],
      clients: [],
      lead: {},
      steps: [],
      followUpDatePicker: false,
      expiryDatePicker: false,
      leadStages: leadStages,
      acceptanceProbabilities: leadProbabilities,
      showCreateContactPopup: false,
      showCreateClientPopup: false,
      showLeadContactPopup: false,
      showDeletePopup: false,
      staff: [],
      settings: {
        existingSalesJob: "J01424",
        newSalesJob: "J02065",
        salesTaskUUID: "9c483d78-0534-4f80-9305-055549bc0f9a",
      }
    }
  },
  mounted() {
    this.lead = {...this.value}
    if (!this.lead.owner_uuid) this.lead.owner_uuid = this.$access.wfm_uuid // default to current user
    this.loadStaff()
    this.loadQuotes()
    this.loadClients()
    this.massageSteps()
  }
})
</script>

<template>
  <v-container>
    <v-form ref="my-form">
      <v-row>
        <v-col>
          <h6>Lead Information</h6>
          <v-text-field label="Title" v-model="lead.title" dense :rules="[isNotEmpty]"></v-text-field>

          <v-textarea label="Description" v-model="lead.description" dense :rules="[isNotEmpty]"></v-textarea>

          <v-autocomplete label="Lead Owner" v-model="lead.owner_uuid" dense :items="staff" :rules="[isNotEmpty]"></v-autocomplete>

          <v-autocomplete label="Client" v-model="lead.client_uuid" dense :items="clientOptions" class="selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" class="display-inline ml-3 pt-1" fab small @click="showCreateClientPopup=true" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create new client</span>
          </v-tooltip>

          <v-autocomplete label="Contact" v-model="lead.contact_uuid" dense :items="contactOptions" class="contact-selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
          <v-btn text x-small dense @click="showLeadContactPopup=true" v-if="lead.contact_uuid">
            <v-icon>mdi-window-restore</v-icon>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" class="display-inline ml-3 pt-1" fab small
                     @click="showCreateContactPopup=true" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create new contact</span>
          </v-tooltip>

          <ClientPopup v-model="showLeadContactPopup" v-if="selectedClient && selectedContact"
                       :selectedClient="selectedClient" :selectedContact="selectedContact"></ClientPopup>

          <div>
            <v-radio-group v-model="lead.is_prospect" :rules="[isNotNull]">
              <v-radio label="Prospect" :value="1"></v-radio>
              <v-radio label="Existing Client" :value="0"></v-radio>
            </v-radio-group>
          </div>

          <v-autocomplete label="Probability of acceptance" v-model="lead.probability"
                          v-if="['quoted', 'won', 'lost'].includes(lead.stage)"
                          :rules="[isNotEmptyExceptLost]"
                          :items="acceptanceProbabilities" dense></v-autocomplete>

          <v-menu v-model="expiryDatePicker" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="lead.expiry" label="Lead Expiry" readonly v-bind="attrs" v-on="on" :rules="[isNotEmpty]"></v-text-field>
            </template>
            <v-date-picker v-model="lead.expiry" @input="expiryDatePicker = false"></v-date-picker>
          </v-menu>

          <div>
            <v-autocomplete label="Lead Stage" v-model="lead.stage" dense :items="leadStages"
                            class="selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" class="display-inline ml-3 pt-1" fab small @click="advanceStage" v-bind="attrs" v-on="on">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>Advance Stage</span>
            </v-tooltip>
          </div>

          <div v-if="lead.stage === 'pre-quote'">
            <v-btn color="primary" class="pt-2" href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/quotes/create" target="_blank">
              <v-icon>mdi-plus</v-icon>
              <span>Create Quote</span>
            </v-btn>
          </div>

          <div v-if="['quoted', 'won', 'lost'].includes(lead.stage)">
            <v-autocomplete label="Quote" v-model="lead.quote_uuid" dense class="quote-selector display-inline"
                            :items="quoteOptions" :rules="[isNotEmptyExceptLost]"></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" class="display-inline ml-5" fab x-small @click="loadQuotes" v-bind="attrs" v-on="on">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Reload Quotes</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="display-inline ml-3 pt-2" fab x-small href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/quotes/create" target="_blank" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create Quote</span>
            </v-tooltip>
          </div>

        </v-col>
        <v-col>
          <h6>Steps</h6>
          <div v-for="(step, idx) in steps" v-bind:key="idx">
            <v-checkbox v-model="step.checked" dense class="display-inline">
              <template v-slot:label>
                <span class="strikethrough" v-if="step.notApplicable">{{ step.name }}</span>
                <span v-else>{{ step.name }}</span>
              </template>
            </v-checkbox>
            <v-btn x-small fab text class="display-inline" @click="toggle(idx, step)">
              N/A
            </v-btn>

          </div>
        </v-col>
        <v-col>
          <h6>Sales</h6>
          <v-textarea label="Lead/Customer/Project Notes" dense persistent-hint v-model="lead.sales_notes"
                      hint="Notes from interactions with the client, for your reference during the sales process. This can include personal points of interest or project notes"></v-textarea>
          <v-textarea label="Follow up action" dense v-model="lead.follow_up_action" class="mt-8" :rules="[isNotEmpty]"
                      hint="What action should be taken at the follow up date" persistent-hint></v-textarea>

          <v-menu v-model="followUpDatePicker" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="lead.follow_up_date" label="Follow Up Date" readonly v-bind="attrs" v-on="on"
                            :rules="[isNotEmpty]"></v-text-field>
            </template>
            <v-date-picker v-model="lead.follow_up_date" @input="followUpDatePicker = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn color="red" @click="showDeletePopup=true" class="full-width" dark>Delete
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" class="full-width">Save
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <v-dialog v-model="showCreateClientPopup">
      <v-card>
        <v-card-title>
          <span class="headline">Create Client</span>
        </v-card-title>
        <v-card-text>
          <CreateProspect @saved="prospectCreated"></CreateProspect>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCreateContactPopup">
      <v-card>
        <v-card-title>
          <span class="headline">Create Contact</span>
        </v-card-title>
        <v-card-text>
          <ContactEditor :clientName="clientName" hide-custom-fields ref="contactEditor"></ContactEditor>
          <v-btn color="primary" @click="saveContact">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeletePopup" :width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Delete</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this lead?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="deleteLead">Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h6 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-selector {
  width: 272px;
}

.selector {
  width: 315px;
}

.quote-selector {
  width: 270px;
}

.display-inline {
  display: inline-block;
}

.strikethrough {
  text-decoration: line-through;
}

.inline-block {
  display: inline-block;
}

</style>